
import { ArticleData } from "@planetadeleste/vue-mc-goodnews";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Youtube } from "vue-youtube";
import { getIdFromUrl, getPictureSizeURL } from "@/services/youtube";
import VFancyBox from "@/components/common/VFancyBox.vue";

@Component({
  components: { Youtube, VFancyBox },
})
export default class InterviewItem extends Vue {
  @Prop(Object) readonly item!: ArticleData;

  obOptions: Record<string, any> = {
    closeButton: "outside",
    on: {
      closing: this.onClose,
    },
  };
  ytOptions: Record<string, any> = {
    autoplay: 1,
  };
  open = false;

  onBeforeOpen(): void {
    this.open = true;
  }

  onClose(): void {
    this.open = false;
  }

  get youtubeId(): string | null {
    return !!this.item && !!this.item.preview_text
      ? getIdFromUrl(this.item.preview_text)
      : null;
  }

  get poster(): string | null {
    return this.youtubeId
      ? getPictureSizeURL(this.youtubeId, "sddefault")
      : null;
  }
}
