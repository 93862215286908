export type PictureSizeName = "maxresdefault" | "sddefault" | "hqdefault";

export const getIdFromUrl = (url: string): string => {
  const [a, , b] = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (b !== undefined) {
    return b.split(/[^0-9a-z_-]/i)[0];
  } else {
    return a;
  }
};

export const getPictureSizeURL = (
  id: string,
  sizeName: PictureSizeName
): string => `https://img.youtube.com/vi/${id}/${sizeName}.jpg`;
